"use client";
import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "@/lib/utils";

const linkVariants = cva(
  "normal-link group inline-flex items-center gap-3 whitespace-nowrap text-center font-default font-semibold  ring-offset-background transition-colors    focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "text-primary dark:text-secondary-foreground",
        secondary: " text-primary-foreground dark:text-secondary-foreground ",
        popover: " text-popover ",
        categories: "text-primary ",
      },
      size: {
        default: "max-h-20 min-h-max text-CTALarge  tracking-[_0.24px]",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof linkVariants> {
  asChild?: boolean;
  leftIconComponentName?: string;
  rightIconComponentName?: string;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      leftIconComponentName,
      rightIconComponentName,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "a";
    const leftIconRef = React.useRef<HTMLDivElement>(null);
    const rightIconRef = React.useRef<HTMLDivElement>(null);
    const textRef = React.useRef<HTMLDivElement>(null);

    const iconFolder = "./../../icons/components/";
    const LeftIcon = leftIconComponentName
      ? React.lazy(
          () => import(`./../../icons/components/${leftIconComponentName}.tsx`),
        )
      : null;
    const RightIcon = rightIconComponentName
      ? React.lazy(
          () =>
            import(`./../../icons/components/${rightIconComponentName}.tsx`),
        )
      : null;

    let timeoutList: NodeJS.Timeout[] = [];

    const handleMouseLeave = (event: any) => {
      const leftIconDiv: HTMLDivElement | null = leftIconRef.current;
      const rightIconDiv: HTMLDivElement | null = rightIconRef.current;
      const textDiv: HTMLDivElement | null = textRef.current;
      textDiv?.classList.add("after:animate-blink");
      timeoutList.push(
        setTimeout(() => {
          textDiv?.classList.remove("after:animate-blink");
        }, 300),
      );
      const icons = [leftIconDiv, rightIconDiv];
      for (const icon of icons) {
        if (icon) {
          icon.classList.add("animate-hoverEndBounce");

          timeoutList.push(
            setTimeout(() => {
              icon.classList.remove("animate-hoverEndBounce");
            }, 200),
          );
        }
      }
    };

    React.useEffect(() => {
      return () => {
        for (const timeout of timeoutList) {
          clearTimeout(timeout);
        }
      };
    }, []);

    return (
      <Comp
        className={cn(linkVariants({ variant, size }), className)}
        // @ts-ignore
        ref={ref}
        onMouseLeave={handleMouseLeave}
        {...props}
      >
        {LeftIcon && (
          <div
            ref={leftIconRef}
            className="div-icon-left flex h-6 w-6 items-center duration-200 group-hover:animate-hoverBounce"
          >
            <React.Suspense fallback={" "}>
              <LeftIcon />
            </React.Suspense>
          </div>
        )}{" "}
        <div
          ref={textRef}
          className={
            "link-text relative flex  justify-center pb-1   after:absolute after:inset-x-0 after:bottom-[-3px] after:h-[1.5px] after:bg-current after:opacity-0 after:content-['']  hover:after:opacity-100 group-hover:translate-y-[0.5px] "
          }
        >
          {props.children}
        </div>
        {RightIcon && (
          <div
            ref={rightIconRef}
            className="div-icon-right flex h-6 w-6 items-center duration-200 group-hover:animate-hoverBounce"
          >
            <React.Suspense fallback={" "}>
              <RightIcon />
            </React.Suspense>
          </div>
        )}{" "}
      </Comp>
    );
  },
);

Link.displayName = "Link";

export { Link, linkVariants };
